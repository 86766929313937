import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Row, Table } from 'react-bootstrap';

import './ReportFin.css';
import { ExportCSV } from '../Work/ExportCSV';

import { actionCreators } from '../../store/Work';
import { years, getCurrentYear } from '../Constants';

class FinPaidDataRCRZ extends Component {
    constructor(props) {
        super(props);
        let nowYear = getCurrentYear()+1;
        this.state = {
            year: props.year !== undefined ? props.year : nowYear,
        }
        this.onCreateReport = this.onCreateReport.bind(this)
    }
    onSelectYear = (e) => {
        let year = parseInt(e.target.value, 10);
        this.setState({ year });
    }
    onCreateReport = (e) => {
        e.preventDefault();
        this.props.getReport1(this.state.year);
    }
    renderRowsTable = (it, i) => {
        return <tr key={i} >
            <td>{it.oblName}</td>
            <td>{it.teName}</td>
            <td>{it.orgKod} {it.orgName}</td>
            <td>
                <div><strong>{it.strDog}</strong></div>
            </td>
        </tr>
    }
    //renderReduceRowsTable = () => {
    //    let ret = [];
    //    let last = this.props.rep1data.items[this.props.rep1data.items - 1];
    //    this.props.rep1data.items.reduce((last, it, i) => {
    //        if (last.OblKod != it.OblKod) {
    //            //найти итог по области
    //            //var itog = this.props.rep1data
    //            ret.push(<tr key={i} >
    //                <td>{it.oblName}</td>
    //                <td colSpan={3}>{it.teName}</td>
    //            </tr>);
    //        }
    //        ret.push(this.renderRowsTable(it,i));
    //        return it;
    //    }, last);
    //    //поместить итог по последней области
    //    return ret;
    //}
    renderItog = () => {
        return <tr key={'itog'}>
            <td colSpan={4}>
                Организаций всего: <strong>{this.props.rep1data.allOrgs}</strong>, из них заключили договор: <strong>{this.props.rep1data.items.length}</strong>
            </td>
        </tr>;
    }
    /*
    getCountOrgsOpl() {
        var count = 0;
        //for (var i = 0; i < this.props.rep1data.items.length; ++i) {
        //    let it = this.props.rep1data.items[i];
        //    if (it.paidQ1 === true && it.paidQ2 === true && it.paidQ3 === true && it.paidQ4 === true)
        //        count++;
        //}
        return count;
    }
    */
    excelDataCSV = () => {
        let dataCSV = [];
        let tobl = I18n.t('obl');
        let traion = I18n.t('raion');
        let torg = I18n.t('org');
        let tdog = I18n.t('dogovor');
        for (var i = 0; i < this.props.rep1data.items.length; i++) {
            let row = {};
            let s = this.props.rep1data.items[i];
            row[tobl] = s.oblName;
            row[traion] = s.teName;
            row[torg] = s.orgKod + ' ' + s.orgName;
            row[tdog] = s.strDog;
            dataCSV.push(row);
        }
        let itog = {};
        itog[tobl] = `Организаций всего: ${this.props.rep1data.allOrgs}, из них заключили договор: ${this.props.rep1data.items.length}`;
        dataCSV.push(itog);
        return dataCSV;
    }
    printData = (e) => {
        e.preventDefault();
        window.print();
    }
    render() {
        var nextYear = getCurrentYear() + 1;
        return (
            <Fragment>
                <h4><Translate value='reportfincrrz' /></h4>
                <Form onSubmit={this.onCreateReport} horizontal>
                    <Row>
                        <Col xs={12} sm={12}>
                            <FormGroup controlId="formYear" className='input-group-sm'>
                                <Col xs={12} sm={2} className="text-right"><ControlLabel><Translate value='year' />{':'}</ControlLabel></Col>
                                <Col xs={12} sm={10}>
                                    <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width:'auto' }}
                                        defaultValue={this.state.year} onChange={this.onSelectYear} disabled={this.props.isLoading === true}>
                                        {years.map(it => <option key={it} value={it}>{it}</option>)}
                                        {/*<option key={nextYear} value={nextYear}>{nextYear}</option>*/}
                                    </FormControl></Col>
                            </FormGroup></Col>
                    </Row>
                    <Button type="submit" bsStyle="primary" bsSize="large" disabled={this.props.isLoading === true}><Translate value="create" /></Button>
                </Form>
                {this.props.rep1data != null && this.props.rep1data.success === true?
                        <Fragment><Button id='bt' type="button" bsStyle="default" onClick={this.printData}>Печать</Button>
                    <ExportCSV csvData={this.excelDataCSV()} fileName={`medstat-finreport-${this.state.year}`} />
                    <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                                <th colSpan={4}><Translate value='reportfincrrz' /> {this.state.year} <Translate value='year2' /></th>
                        </tr>
                        <tr>
                                <th><Translate value='obl' /></th>
                                <th><Translate value='raion' /></th>
                                <th><Translate value='org' /></th>
                                <th><Translate value='dogovor' /></th>
                        </tr>
                    </thead>
                    <tbody>
                                {this.props.rep1data.items.map((it, i) => this.renderRowsTable(it, i))}
                                {this.renderItog()}
                        </tbody>
                            </Table>
                    </Fragment> : ''}
                {this.props.rep1data != null && this.props.rep1data.success === true ? <div className='text-danger'>{this.props.rep1data.error}</div> : ''}
            </Fragment>
        );
    }
}
// {this.props.rep1data.items.map((it, i) => this.renderRowsTable(it, i))}
export default connect(
    state => state.work,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(FinPaidDataRCRZ);
