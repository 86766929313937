import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, FormGroup, FormControl, ControlLabel, Row, Col, Alert, Checkbox } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import NumberFormat from 'react-number-format';
import * as FileSaver from 'file-saver';
import { actionCreators } from '../../store/Medinfo/FormulMedinfo';
//import SelectMultiObjects from '../common/SelectMultiObjects'
import SelectObject2 from '../common/SelectObject2';
import { arTypePers, arTypeDiagram, getNameLocalize, periods, years as arYears } from '../Constants';
//import loading from '../../images/loading.gif';
class GenMedinfoTable2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type_page: 1,//1,2
            selectedPart: undefined,
            selectedTable: undefined,
            selectedPage: undefined,
            type_per: 1,
            begYear: 2017,
            endYear: 2018,
            //isCustomYears: false,//если используется строка годов
            yearsExprStrings: "2017-2018",//строка годов (например - 2017-2018,2021)
            years: [2017, 2018], //массив годов из строки yearsExprStrings 
            tobj: 1,//props.userInfo.user.accessLevel, // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
            obl: undefined,
            raion: undefined,
            org: { korg: this.props.userInfo.user.kodOrg },//, undefined,
            //kobls: [],
            //kraions: [],
            //korgs: [],
            isOneMonth: true,
            isForOrgs: false,
            oneMonth: "101",
            isCreateDiagram: false,
            type_diagram: 1,
            diagramColumn: 1,
            diagramPeriod: 2017,
            diagramLang: 'kk',
            isLoading: false,
            erMonthQuart: undefined
        };
        //this.onClickTable = this.onClickTable.bind(this);
        this.onClickPart = this.onClickPart.bind(this);
        this.onClickPage = this.onClickPage.bind(this);
        this.showListPages = this.showListPages.bind(this);
        this.begYearChange = this.begYearChange.bind(this);
        this.endYearChange = this.endYearChange.bind(this);
        this.onGenerateTable = this.onGenerateTable.bind(this);
        this.yearsExprStringsChange = this.yearsExprStringsChange.bind(this);
        this.yearsList = this.yearsList.bind(this);
        this.showSelectColumn = this.showSelectColumn.bind(this);
        this.onSelectDiagramColumn = this.onSelectDiagramColumn.bind(this);
        this.showSelectDiagramPeriod = this.showSelectDiagramPeriod.bind(this);
        this.onSelectDiagramPeriod = this.onSelectDiagramPeriod.bind(this);
        this.showSelectDiagramLang = this.showSelectDiagramLang.bind(this);
        this.onSelectDiagramLang = this.onSelectDiagramLang.bind(this);
        this.showWarningDiagram = this.showWarningDiagram.bind(this);
        this.onSetTobj = this.onSetTobj.bind(this);
        this.setIsForOrgs = this.setIsForOrgs.bind(this);
        this.changeTypePer = this.changeTypePer.bind(this);

        /*this.props.getTablesPages(this.state.type_page).finally(() => {
            let selectedTable = this.props.formulmedinfo.tablesPages.tables[0];
            this.setState({
                selectedTable: selectedTable, selectedPage: selectedTable.pages[0]
            });
       });
       */
        //if (I18nState.locale == 'ru') {

        //}
        this.props.getParts().finally(() => {
            let selectedPart = (this.props.formulmedinfo.parts != null && this.props.formulmedinfo.parts.length > 0) ? this.props.formulmedinfo.parts[0] : undefined;
            //this.setState({ selectedPart });
            this.props.getPagesOfPart(selectedPart.partId).finally(() => {
                let selectedPage = (this.props.formulmedinfo.partPages != null && this.props.formulmedinfo.partPages.length > 0) ? this.props.formulmedinfo.partPages[0] : undefined;
                this.setState({ selectedPart, selectedPage, type_page: selectedPage.typePage, diagramColumn: 1, type_per: 1 });
            });
        });
    }
    //onSelectPart = async (e) => {
    //    /*
    //    let type_page = parseInt(e.target.value, 10);
    //    await this.props.getTablesPages(type_page);
    //    let selectedTable = this.props.formulmedinfo.tablesPages.tables[0];
    //    this.setState({
    //        type_page, selectedTable: selectedTable, selectedPage: selectedTable.pages[0]
    //    });
    //    */
    //}
    onClickPart = (e) => {
        let partId = parseInt(e.target.value, 10);
        let selectedPart = this.props.formulmedinfo.parts.filter(t => t.partId === partId)[0];
        //this.setState({ selectedPart });
        //todo load pages for part
        this.props.getPagesOfPart(partId).finally(() => {
            let selectedPage = (this.props.formulmedinfo.partPages != null && this.props.formulmedinfo.partPages.length > 0) ? this.props.formulmedinfo.partPages[0] : undefined;
            this.setState({ selectedPart, selectedPage, type_page: selectedPage.typePage, diagramColumn: 1, type_per:1 });
        });
    }
    showListParts() {
        if (this.props.formulmedinfo.parts == null || this.props.formulmedinfo.parts.length === 0) return '';
        return <FormGroup controlId="formParts" className='input-group-sm'>
            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="part" />{':'}</ControlLabel></Col>
            <Col xs={12} sm={8}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                    defaultValue={this.state.selectedPart} onChange={this.onClickPart}>
                    {this.props.formulmedinfo.parts.map(l => <option value={l.partId} key={l.partId}>{l.kod} {getNameLocalize(this.props.i18n, l)}</option>)}
                </FormControl></Col>
        </FormGroup>;
    }
    onClickPage = (e) => {
        let pageId = parseInt(e.target.value, 10);
        let selectedPage = this.props.formulmedinfo.partPages.filter(t => t.pageId === pageId)[0];
        this.setState({ selectedPage, type_page: selectedPage.typePage, diagramColumn: 1 });
    }
    showListPages() {
        if (this.state.selectedPart == null || this.props.formulmedinfo.partPages == null || this.props.formulmedinfo.partPages.length === 0) return '';
        return <FormGroup controlId="formTypePage" className='input-group-sm'>
            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="page" />{':'}</ControlLabel></Col>
            <Col xs={12} sm={8}>
                <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                    defaultValue={this.state.selectedPage} onChange={this.onClickPage}>
                    {this.props.formulmedinfo.partPages.map(p => <option value={p.pageId} key={p.pageId}>{p.kod} {getNameLocalize(this.props.i18n, p)}</option>)}
                </FormControl></Col>
        </FormGroup>;
    }
    begYearChange(values) {
        let vl = parseInt(values.value, 10); //values.intValue === undefined ? 2017 : values.intValue;
        this.setState({ begYear: vl, erMonthQuart: undefined });
    }
    endYearChange(values) {
        let vl = parseInt(values.value, 10); // values.intValue === undefined ? 2018 : values.intValue;
        this.setState({ endYear: vl });
    }
    validYearsMessage() {
        if (this.state.type_per > 1) return '';
        if (this.state.begYear >= this.state.endYear) {
            return <div className="text-danger">Первый год не должен быть равен или больше чем второй</div>;
        }
        if (this.state.endYear - this.state.begYear > 10) {
            return <div className="text-danger">Количество лет не должно быть больше 10</div>;
        }
    }
    validYearsExprStrings() {
        if (this.state.type_per > 1) return '';
        if (this.state.yearsExprStrings == null || this.state.yearsExprStrings.length === 0)
            return <div className="text-danger">Введите выражение</div>;
        //проверка регулярного выражения
        //let regexp = /(\d{4}(,)?|\d{4}-\d{4}(,)?)+/;
        let regexp = /\d{4,4}-\d{4,4}|\d{4,4}/gi;
        let test = regexp.test(this.state.yearsExprStrings);
        if (!test)
            return <div className="text-danger"><Translate value="genMedinfo.yearsError1" /></div>;
        if (this.state.years.length > 10)
            return <div className="text-danger"><Translate value="genMedinfo.yearsError2" /></div>;
        if (this.state.years.length === 0)
            return '';
        if (this.state.years[0] < arYears[0]) {
            return <div className="text-danger"><Translate value="genMedinfo.yearsError3" year={arYears[0]} /></div>;
        }
        let lastPos = arYears.length - 1;
        let lastPos0 = this.state.years.length - 1;
        if (this.state.years[lastPos0] > arYears[lastPos]) {
            return <div className="text-danger"><Translate value="genMedinfo.yearsError4" year={arYears[lastPos]} /></div>;
        }
        //let ar =this.state.yearsExprStrings.match(regexp);
        //return <div className="text-danger">test={test?'true':'false'}  {ar.join(',')}</div>
    }
    yearsList() {
        if (this.state.years.length > 0)
            return <strong className="text-primary">{this.state.years.join(',')}</strong>
    }
    yearsExprStringsChange = (e) => {
        //получить масив годов
        let yearsExprStrings = e.target.value;
        let years = [];
        let regexp = /(\d{4,4}-\d{4,4})|(\d{4,4})/gi;
        let test = regexp.test(yearsExprStrings);
        let diagramPeriod = this.state.diagramPeriod;
        if (test) {
            let ar = yearsExprStrings.match(regexp);
            for (var i = 0; i < ar.length; i++) {
                let it = ar[i];
                if (it.indexOf('-') !== -1) {
                    let ar1 = it.split('-');
                    let yy1 = parseInt(ar1[0], 10);
                    let yy2 = parseInt(ar1[1], 10);
                    //eslint-disable-next-line
                    for (var yi = yy1; yi <= yy2; yi++) {
                        //eslint-disable-next-line
                        if (years.filter(y1 => y1 === yi).length === 0) {
                            years.push(yi);
                        }
                    }
                } else {
                    let ye = parseInt(it, 10);
                    if (years.filter(y1 => y1 === ye).length === 0) {
                        years.push(ye);
                    }
                }
            }
            years = years.sort();
            if (years.length > 0)
                diagramPeriod = years[0];
        }
        this.setState({ yearsExprStrings, years, diagramPeriod, erMonthQuart: undefined });
    }
    showRangeYears() {
        if (this.state.type_per === 1 || this.state.type_per === 3) {
            return <Fragment><Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formYearBeg" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="yearsExprStrings" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <input value={this.state.yearsExprStrings} onChange={this.yearsExprStringsChange} />&nbsp;
                            {this.yearsList()}
                            {this.validYearsExprStrings()}
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
                {this.state.type_per === 3 ? <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formMonth" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="months" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <select className="form-control" value={this.state.oneMonth} onChange={(evt) => this.setState({ oneMonth: evt.target.value })} required>
                                    {periods.filter(p => p.kper > 100).map(it => <option key={it.kper} value={it.kper}>{I18n.t(it.nameT)}</option>)}
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row> : ''}
            </Fragment>;
            /*
            return <Fragment>
                <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formYearBeg" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="yearBeg" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <NumberFormat value={this.state.begYear} onValueChange={(values) => this.begYearChange(values)} /></Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formYearEnd" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="yearEnd" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <NumberFormat value={this.state.endYear} onValueChange={(values) => this.endYearChange(values)} /></Col>
                        </FormGroup>
                        {this.validYearsMessage()}
                    </Col>
                </Row></Fragment>;
            */
        } else {
            return <Fragment> <Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formYearBeg" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="year" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <NumberFormat value={this.state.begYear} onValueChange={(values) => this.begYearChange(values)} autoComplete="off" /></Col>
                    </FormGroup>
                </Col>
            </Row>
                {this.state.type_per === 3 ? <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formPokDiagr" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="months" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <select className="form-control" value={this.state.oneMonth} onChange={(evt) => this.setState({ oneMonth: evt.target.value })} required>
                                    {periods.filter(p => p.kper > 100).map(it => <option key={it.kper} value={it.kper}>{I18n.t(it.nameT)}</option>)}
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row> : ''}
                {this.state.type_per === 5 ? <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formPokDiagr" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="quarter" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <select className="form-control" value={this.state.oneMonth} onChange={(evt) => this.setState({ oneMonth: evt.target.value })} required>
                                    {periods.filter(p => p.kper > 4 && p.kper < 100).map(it => <option key={it.kper} value={it.kper}>{I18n.t(it.nameT)}</option>)}
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row> : ''}
            </Fragment>;
        }
    }
    onGenerateTable = async (e) => {
        e.preventDefault();
        let kobjs = null;
        this.setState({erMonthQuart: undefined});
        switch (this.state.tobj) {
            case 1:
                kobjs = ["00"];
                break;
            case 2:
                // if (this.props.userInfo.user.accessLevel === 2) {
                kobjs = [];
                kobjs.push(this.state.obl.kobl);
                // } else {
                //     kobjs = this.state.kobls;
                // }
                break;
            case 3:
                //if (this.props.userInfo.user.accessLevel === 3) {
                kobjs = [];
                kobjs.push(this.state.raion.kraion);
                //} else {
                //    kobjs = this.state.kraions;
                //}
                break;
            case 4:
                kobjs = [];
                kobjs.push(this.state.org.korg/*this.props.userInfo.user.kodOrg*/);
                break;
            default:
        }
        if (this.state.type_per === 1 && this.state.years.length === 0) {
            alert("Выберте года");
            return;
        } else if (this.state.begYear == null || this.state.begYear === 0) {
            alert("Выберте год");
            return;
        }
        if (kobjs.length === 0) {
            alert("Выберте обьекты");
            return;
        }
        if (this.state.type_per != 1) {
            switch (this.state.type_per) {
                case 3: //по одному месяцу за несколько лет
                    for (var i in this.state.years) {
                        if (this.state.years[i] < 2024) {
                            this.setState({ erMonthQuart: I18n.t("erMonthQuart") });
                            return;
                        }
                    }
                    break;
                case 2://квартал данные месяцев без наростания с начала года
                case 4://квартал данные месяцев с наростанием с начала года
                case 5://квартал по выбору
                    if (this.state.begYear < 2024) {
                        this.setState({ erMonthQuart: I18n.t("erMonthQuart") });
                        return;
                    }
                    break;
                default:
            }
        }
        let years = this.state.years;
        //if (this.state.type_per === 3) {
        //    years = [];
        //    years.push(this.state.years[0]);
        //}
        let body = {
            typePage: this.state.type_page,//1,2
            tableId: this.state.selectedPage.tableId,//this.state.selectedTable.tableId,
            pageId: this.state.selectedPage.pageId,
            typePer: this.state.type_per,
            years: years,
            begYear: this.state.begYear,
            endYear: this.state.endYear,
            tobj: this.state.tobj,
            kobjs,
            isOneMonth: this.state.isOneMonth,
            isForOrgs: this.state.isForOrgs,
            oneMonth: this.state.oneMonth,
            isCreateDiagram: this.state.isCreateDiagram,
            typeDiagram: this.state.type_diagram,
            diagramColumn: this.state.diagramColumn,
            diagramPeriod: this.state.diagramPeriod,
            diagramLang: this.state.diagramLang
        };
        this.setState({ isLoading: true });
        let absoluteUrl = `${document.location.protocol}//${document.location.host}/Medinfo/generate/Excel`;
        let fileName = `${this.state.type_per === 1 || this.state.type_per === 3 ? years[0] : this.state.begYear}_${this.state.type_page}_${this.state.selectedPage.tableKod}_${this.state.selectedPage.pageKod}_${this.state.type_per}_${this.state.tobj}.xlsx`;
        var token = this.props.userInfo.user.token;
        const response = await fetch(absoluteUrl, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            // получаем тело ответа (см. про этот метод ниже)
            const blob = await response.blob();
            FileSaver.saveAs(blob, fileName);
            this.setState({ isLoading: false });
        } else {
            alert("Ошибка HTTP: " + response.status);
        }
    }
    onSelectDiagramColumn = (e) => {
        let diagramColumn = parseInt(e.target.value, 10);
        this.setState({ diagramColumn });
    }
    showSelectColumn() {
        if (this.state.selectedPage == null)
            return '';
        return <Row>
            <Col xs={12} sm={6}>
                <FormGroup controlId="formPokDiagr" className='input-group-sm'>
                    <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="indicator" />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={8}>
                        <select className="form-control" value={this.state.diagramColumn} onChange={this.onSelectDiagramColumn} required>
                            {this.state.selectedPage.columns.map(it => <option key={it.npp} value={it.npp}>{getNameLocalize(this.props.i18n, it)}</option>)}
                        </select>
                    </Col>
                </FormGroup>
            </Col>
        </Row>;
    }
    onSelectDiagramPeriod = (e) => {
        let diagramPeriod = parseInt(e.target.value, 10);
        this.setState({ diagramPeriod });
    }
    showSelectDiagramPeriod() {
        if (this.state.selectedPage == null)
            return '';
        if (this.state.type_diagram !== 3 && this.state.type_diagram !== 5)
            return '';
        switch (this.state.type_per) {
            case 1:
                return <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formPokDiagr" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="year" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <select className="form-control" value={this.state.diagramPeriod} onChange={this.onSelectDiagramPeriod} required>
                                    {this.state.years.map(it => <option key={it} value={it}>{it}</option>)}
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>;
            //break;
            case 2://квартал данные месяцев с наростанием с начала года
                return <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formPokDiagr" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="year" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <select className="form-control" value={this.state.diagramPeriod} onChange={this.onSelectDiagramPeriod} required>
                                    {periods.filter(p => p.kper == 4 || p.kper == 10 || p.kper == 21 || p.kper == 31).map(it => <option key={it.kper} value={it.kper}>{I18n.t(it.nameT)}</option>)}
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>;
            // break;
            case 3:
                return <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formPokDiagr" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="year" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <select className="form-control" value={this.state.diagramPeriod} onChange={this.onSelectDiagramPeriod} required>
                                    {periods.filter(p => p.kper > 100).map(it => <option key={it.kper} value={it.kper}>{I18n.t(it.nameT)}</option>)}
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>;
            //break;
            case 4://квартал данные месяцев без наростания с начала года
                return <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formPokDiagr" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="year" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <select className="form-control" value={this.state.diagramPeriod} onChange={this.onSelectDiagramPeriod} required>
                                    {periods.filter(p => p.kper >= 4 && p.kper <= 40).map(it => <option key={it.kper} value={it.kper}>{I18n.t(it.nameT)}</option>)}
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>;
            default:
                return '';
        }
    }
    onSelectDiagramLang = (e) => {
        let diagramLang = e.target.value;
        this.setState({ diagramLang });
    }
    showWarningDiagram() {
        if (this.state.type_diagram === 3 || this.state.type_diagram === 5 || this.state.years.length < 4)
            return '';
        return <Row>
            <Col xs={12} sm={6}>
                <Alert bsStyle="warning"><Translate value="showWarningDiagram" /></Alert>
            </Col>
        </Row>;
    }
    showSelectDiagramLang() {
        return <Fragment><Row>
            <Col xs={12} sm={6}>
                <FormGroup controlId="formLangDiagr" className='input-group-sm'>
                    <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="lang" />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={8}>
                        <select className="form-control" value={this.state.diagramLang} onChange={this.onSelectDiagramLang} required>
                            <option key={'kk'} value={'kk'}>Қаз</option>)
                            <option key={'ru'} value={'ru'}>Рус</option>)
                        </select>
                    </Col>
                </FormGroup>
            </Col>
        </Row>{this.showWarningDiagram()}
        </Fragment>;
    }
    onSetTobj = (tobj) => {
        if (tobj < 3 && tobj != 2) {
            this.setState({ tobj, isForOrgs: false });
        } else {
            this.setState({ tobj, isCreateDiagram: false, isForOrgs: false });
        }
    }
    showTypesDiagram() {
        if (this.state.isCreateDiagram === false/* && this.state.tobj > 3*/) return '';
        return <Fragment><Row>
            <Col xs={12} sm={6}>
                <FormGroup controlId="formTypeDiagr" className='input-group-sm'>
                    <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="type_diagram" />{':'}</ControlLabel></Col>
                    <Col xs={12} sm={8}>
                        <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                            defaultValue={this.state.type_diagram} onChange={(e) => this.setState({ type_diagram: parseInt(e.target.value, 10) })}>
                            {arTypeDiagram.filter(f => !(this.state.type_page === 2 && f.id === 5) /*&& this.state.tobj<3*/).map(it => <option key={it.id} value={it.id}>{I18n.t(it.nameT)}</option>)}
                        </FormControl></Col>
                </FormGroup>
            </Col>
        </Row>
            {this.showSelectDiagramPeriod()}
            {this.showSelectColumn()}
            {this.showSelectDiagramLang()}
        </Fragment>;
    }
    setIsForOrgs(value) {
        if (this.state.tobj === 2 && value === true)
            this.setState({ isForOrgs: value, isCreateDiagram: false });
        else {
            this.setState({ isForOrgs: value });
        }
    }
    changeTypePer(e){
        let type_per = parseInt(e.target.value, 10);
        let diagramPeriod = 0;//
        switch (type_per) {
            case 1://за год
                if (this.state.years.length > 0)
                    diagramPeriod = this.state.years[0];
                break;
            case 2://за квартал данные по месяцам с наростания с начала года 
                diagramPeriod = 1;
                break;
            case 3:
                diagramPeriod = 101;
                break;
            case 4://за квартал данные по месяцам только за месяц 
                diagramPeriod = 1;
                break;
            case 5://за квартал данные по месяцам только за месяц 
                diagramPeriod = 10;
                let oneMonth = "10";
                this.setState({ type_per, oneMonth, diagramPeriod });
                return; 
            default:
                this.setState({ type_per });
                return;
        }
        this.setState({ type_per, diagramPeriod, erMonthQuart: undefined });
    }
    render() {
        return <Form onSubmit={this.onGenerateTable} horizontal>
            <h4 className="text-center"><Translate value="generateTableMedinfo" /></h4>
            <Alert bsStyle="info"><Translate value="medinfoComment" /></Alert>
            {this.props.formulmedinfo.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('wait')} width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
            <Row>
                <Col xs={12} sm={6}>
                    {this.showListParts()}
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    {this.showListPages()}
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formTypePer" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="type_per" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                                defaultValue={this.state.type_per} onChange={(e) => this.changeTypePer(e)}>
                                {/*{arTypePers.filter(f => f.id === 1 || f.id === 3 || (this.state.selectedPart != null && this.state.selectedPart.partId != 20 && f.id === 2) || (this.state.selectedPart != null && this.state.selectedPart.partId === 20 && (f.id === 4 || f.id === 5 )))
                                    .map(it => <option key={it.id} value={it.id}>{I18n.t(it.nameT)}</option>)}*/}
                                {arTypePers.filter(f => f.id === 1 || (this.state.selectedPart != null && this.state.selectedPart.partId === 20 && (f.id === 3 || f.id === 4 || f.id === 5)))
                                    .map(it => <option key={it.id} value={it.id}>{I18n.t(it.nameT)}</option>)}
                            </FormControl></Col>
                    </FormGroup>
                </Col>
            </Row>
            {this.showRangeYears()}
            {this.state.erMonthQuart != null ? <Row>
                <Col xs={12} sm={6}>
                    <Alert bsStyle="warning"><Translate value="erMonthQuart" /></Alert>
                </Col>
            </Row>:""}
            <SelectObject2
                tobj={this.state.tobj}
                obl={this.state.obl}
                raion={this.state.raion}
                org={this.state.org}
                setTobj={(tobj) => this.onSetTobj(tobj)}
                setObl={(obl) => this.setState({ obl })}
                setRaion={(raion) => this.setState({ raion })}
                setOrg={(org) => this.setState({ org })}
            />
            {this.state.tobj === 2 && this.state.selectedPage.typePage === 1 ?
                <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formisForOrgs" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="genMedinfo.forOrg" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <Checkbox checked={this.state.isForOrgs === true} onChange={(e) => this.setIsForOrgs(e.target.checked)}></Checkbox>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row> : ''}
            {(this.state.tobj < 3 && this.state.isForOrgs === false) || (this.state.selectedPage != null && this.state.selectedPage.typePage === 2) ?
                <Fragment><Row>
                    <Col xs={12} sm={6}>
                        <FormGroup controlId="formisCreateDiagram" className='input-group-sm'>
                            <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="createDiagram" />{':'}</ControlLabel></Col>
                            <Col xs={12} sm={8}>
                                <Checkbox checked={this.state.isCreateDiagram === true} onChange={(e) => this.setState({ isCreateDiagram: e.target.checked })}></Checkbox>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                    {this.showTypesDiagram()}
                </Fragment> : ''}
            {this.state.isLoading === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" width={20} />{' '}<strong><Translate value="wait" /></strong></div>
                </Col>
            </Row> : ''}
            {this.props.formulmedinfo.error != null ? <Row>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="danger">{this.props.formulmedinfo.error}</Alert>
                </Col>
            </Row> : ''}
            <Row>
                <FormGroup>
                    <Col smOffset={2} sm={10}>
                        <Button className='btn btn-primary text-center' type="submit"><Translate value="btGenMedinfoTable" /></Button>
                    </Col>
                </FormGroup>
            </Row>
        </Form>;
    }
}//
export default connect(
    state => state,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(GenMedinfoTable2);
/*
 <Row>
                <Col xs={12} sm={6}>
                    <FormGroup controlId="formTypePage" className='input-group-sm'>
                        <Col xs={12} sm={4} className="text-right"><ControlLabel><Translate value="type_table" />{':'}</ControlLabel></Col>
                        <Col xs={12} sm={8}>
                            <FormControl className='input-sm' componentClass="select" placeholder="select" style={{ width: "auto" }}
                                defaultValue={this.state.type_page} onChange={this.onSelectTypePage}>
                                <option value={1}>{1}</option>
                                <option value={2}>{2}</option>
                            </FormControl></Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    {this.showListTables()}
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    {this.showListPages()}
                </Col>
            </Row>
 */