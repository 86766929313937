import React, { Fragment, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Col, FormGroup, FormControl, ControlLabel, Form, Checkbox, Radio, HelpBlock, Row, Alert, Modal } from 'react-bootstrap';

import { actionCreators as actionCreatorsSvod } from '../../store/Svod';
import { actionCreators as actionCreatorsNsi } from '../../store/Nsi';
//import SelectMultiObjects from '../common/SelectMultiObjects';
//import SelectMultiForms from '../common/SelectMultiForms';
import SelectMultiForms from '../common/SelectMultiForms';
import SelectMultiObjects from '../common/SelectMultiObjects';

import FilterRazOptions from '../common/FilterRazOptions';
import { years, getCurrentYear, getNameLocalize } from '../Constants';

const SvodFormQuarts = (props) => {
    useState(() => {
        if (props.nsi.allobls.length === 0) props.getNs('allobls');
        if (props.nsi.sprraz.length === 0) props.getNs('sprraz');
        if (props.nsi.sprform.length === 0) props.getNs('sprform');
        if (props.nsi.periods.length === 0) props.getNs('periods');
        if (props.nsi.allraions.length === 0) props.getNs('allraions');
        if (props.userInfo.user.nomenklKod === '008' || props.userInfo.user.nomenklKod === '049' || props.userInfo.user.nomenklKod === '043') {
            const fetchData = async () => {
                const chOrgs = await props.getSubOrgs();
                setChildOrgs(chOrgs);
            }
            fetchData();
        }
    });
    const [year, setYear] = useState(() => getCurrentYear());
    const [kper, setKper] = useState(4);
    const [krazSrc, setKrazSrc] = useState(1);
    const [tobj, setTobj] = useState(props.userInfo.user.accessLevel); // тип обьекта 1 – РК; 2 – область; 3 - район; 4 - организация (доступ зависит от пользователя)
    const [kobl, setKobl] = useState(props.userInfo.user.kodObl);
    const [kraion, setKraion] = useState(props.userInfo.user.kodRaion.substring(0, 4));
    const [korg, setKorg] = useState(props.userInfo.user.kodOrg);
    const [kobls, setKobls] = useState([props.userInfo.user.kodObl]);
    const [kraions, setKraions] = useState([props.userInfo.user.kodRaion.substring(0, 4)]);
    const [korgs, setKorgs] = useState([]);
    const [kforms, setKforms] = useState([]);
    const [forOrgs, setForOrgs] = useState(false);
    const [isSelectOrgAll, setIsSelectOrgAll] = useState();
    const [childOrgs, setChildOrgs] = useState();
    const [errors, setErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState();
    const [svodRunning, setSvodRunning] = useState(false);
    const setParam = (param, value) => {
        //setSelOrgs([]);
        //setOrgs([]);
        switch (param) {
            case "year":
                setYear(value);
                break;
            case "kper":
                setKper(value);
                break;
            case "krazSrc":
                setKrazSrc(value);
                break;
            case "tobj":
                setTobj(value);
                break;
            case "kobl":
                setKobl(value.kobl);
                let ra = props.nsi.allraions.filter(r => r.obl === value.kobl);
                if (ra.length > 0) {
                    setKraion(ra[0].idRaion);
                } break;
            case "kraion":
                setKraion(value.kraion);
                break;
            case "korg":
                setKorg(value.korg);
                break;
            case "kobls":
                setKobls(value);
                if (value.length > 0 && errors.length > 0) {
                    let ind = errors.indexOf("selectObjs");
                    if (ind > -1) {
                        let ar = [...errors];
                        ar.splice(ind, 1);
                        setErrors(ar);
                    }
                }
                break;
            case "kraions":
                setKraions(value);
                if (value.length > 0 && errors.length > 0) {
                    let ind = errors.indexOf("selectObjs");
                    if (ind > -1) {
                        let ar = [...errors];
                        ar.splice(ind, 1);
                        setErrors(ar);
                    }
                }
                break;
            case "korgs":
                setKorgs(value);
                if (value.length > 0 && errors.length > 0) {
                    let ind = errors.indexOf("selectObjs");
                    if (ind > -1) {
                        let ar = [...errors];
                        ar.splice(ind, 1);
                        setErrors(ar);
                    }
                }
                break;
            //case "isAllRaions":
            //    setIsAllRaions(value);
            //    break;
            case "kforms":
                setKforms(value);
                if (value.length > 0 && errors.length > 0) {
                    let ind = errors.indexOf("selectForms");
                    if (ind > -1) {
                        let ar = [...errors];
                        ar.splice(ind, 1);
                        setErrors(ar);
                    }
                }
                break;
            default:
        }
    }
    const getSvodParams = (fnErr) => {
        setErrors([]);
        setSuccessMessage(null);
        let errs = [];
        let kobjs = [];
        switch (tobj) {
            case 1:
                kobjs = ["99"];
                break;
            case 2:
                kobjs = kobls;
                break;
            case 3:
                //kobjs = kraions;
                if (props.userInfo.user.accessLevel === 3) {
                    if (forOrgs != true) {
                        kobjs = kraions;
                    } else {
                        kobjs = korgs;
                    }
                } else {
                    kobjs = kraions;
                }
                break;
            case 4:
                kobjs = [korg];
                break;
            default:
        }
        if (kobjs.length === 0) {
            errs.push("selectObjs");
        }
        if (kforms.length === 0) {
            errs.push("selectForms");
        }
        if (errs.length > 0)
            fnErr(errs);
        return {
            year,
            kper,
            kraz:krazSrc,
            tobj: forOrgs === true ? 4 : tobj,
            kobjs,
            kforms
        };
    }
    const onSvodFormQuarts = (e) => {
        e.preventDefault();
        const svodQuarts = async () => {
            let errs = [];
            let param = getSvodParams((ers) => errs = ers);
            if (errs.length > 0) {
                setErrors(errs);
                return;
            };
            setSvodRunning(true);
            var ret = await props.svodFormQuarts(param);
            if (ret.successed === false) {
                setErrors([ret.error]);
            } else {
                setSuccessMessage(I18n.t("svodComplete"));
            }
            setSvodRunning(false);
        }
        svodQuarts();
    }
    const showCommentPeriod = (per) => {
        switch (per) {
            case 10:
                return <Translate value='quartsComments.q10' className="text-primary"/>;
            case 20:
                return  <Translate value='quartsComments.q20' className="text-primary"/>;
            case 21:
                return  <Translate value='quartsComments.q21' className="text-primary"/>;
            case 30:
                return  <Translate value='quartsComments.q30' className="text-primary"/>;
            case 31:
                return  <Translate value='quartsComments.q31' className="text-primary"/>;
            case 40:
                return  <Translate value='quartsComments.q40' className="text-primary"/>;
            case 4:
                return  <Translate value='quartsComments.q4' className="text-primary"/>;
            default:
                return "";
        }
    }
    const isSelectOrgAllChange = (evt) =>{
        //evt.preventDefault();
        if (evt.target.checked === true) {
            setIsSelectOrgAll(true)
            setKorgs(cloneDeep(childOrgs.map(m => m.kod)));
        } else {
            setIsSelectOrgAll(false);
            setKorgs([props.userInfo.user.kodOrg]);
        }
    }
    const showSubOrgs= () => {
        if ((props.userInfo.user.nomenklKod === '008' || props.userInfo.user.nomenklKod === '049' || props.userInfo.user.nomenklKod === '043') && tobj === 3) {
            return <Fragment><FormGroup bsSize='small'>
                <Col smOffset={2}>
                    <Radio name="radioGroupForOrgs" onClick={() => { setForOrgs(false); setIsSelectOrgAll(false); setKorgs([props.userInfo.user.kodOrg]); }} defaultChecked={forOrgs === false} inline><Translate value="forRaion" /></Radio>{' '}
                    <Radio name="radioGroupForOrgs" onClick={() => { setForOrgs(true); setIsSelectOrgAll(false); setKorgs([props.userInfo.user.kodOrg]); }} defaultChecked={forOrgs === true} inline><Translate value="forSub" /></Radio>{' '}&nbsp;&nbsp;&nbsp;
                    {forOrgs === true ? <Checkbox onChange={isSelectOrgAllChange} defaultChecked={isSelectOrgAll === true} inline><Translate value="selectAllOrgs" /></Checkbox> : ''}
                </Col>
            </FormGroup>
                {showListSub()}
            </Fragment>
        } else {
            return '';
        }
    }
    const getValidationChildOrgs= () => {
        if (forOrgs != true) return null;
        const length = korgs.length;
        if (length > 0)
            return 'success';
        if (length === 0) {
            return 'error';
        }
        return null;
    }
    const showListSub = () => {
        if (forOrgs != true) return '';
        return <FormGroup bsSize='small' controlId="subOrgs" validationState={getValidationChildOrgs()}>
            <Col smOffset={2}>
                {childOrgs.map(it => <Checkbox onChange={onCheckChildOrg} checked={korgs.includes(it.kod) === true} key={it.kod} value={it.kod} inline>{it.kod} {getNameLocalize(props.i18n, it)}</Checkbox>)}
            </Col>
        </FormGroup>;
    }
    const onCheckChildOrg = (e) => {
        //e.preventDefault();
        let checked = e.target.checked;
        let org = e.target.value;
        let krgs = [...korgs];
        if (checked) {
            krgs.push(org);
        } else {
            let ind = krgs.indexOf(org);
            krgs.splice(ind, 1);
        }
        setKorgs(krgs);
    }

    let isReadOnLy = props.userInfo.user.roles.filter(r => r === 'UserReadData').length > 0;
    if (isReadOnLy === true)
        return <Translate value="noAccess" />
    else
        return <><Form onSubmit={onSvodFormQuarts} horizontal>
            <h5 style={{ textAlign: "center" }}><strong><Translate value="svodFormQuart" /></strong></h5>
            <FormGroup controlId="formYear" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='year' />{':'}</Col>
                <Col sm={10}>
                    <FormControl className='input-sm' componentClass="select" placeholder="select" disabled={props.svod.isLoading}
                        defaultValue={year} onChange={(e) => setParam("year", parseInt(e.target.value, 10))} bsSize="small" style={{ width: "auto" }}>
                        {years.map(it => <option key={it} value={it}>{it}</option>)}
                    </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formPer" bsSize='small'>{/*defaultValue={`${kper}`}*/}
                <Col sm={2} componentClass={ControlLabel}><Translate value='period' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={`${kper}`} disabled={props.svod.isLoading}
                    onChange={(e) => setParam("kper", parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                    {props.nsi.periods.filter(f => f.kper === 4 || f.kper === 10 || f.kper === 20 || f.kper === 21 || f.kper === 30 || f.kper === 31 || f.kper === 40).map(it => <option key={it.kper} value={it.kper}>{it.nameRu}</option>)}
                    {/* <option key={4} value={4}>{I18n.t("period4")}</option>*/}
                </FormControl>{showCommentPeriod(kper)}</Col>
            </FormGroup>
            <FormGroup controlId="formRaz" bsSize='small'>
                <Col sm={2} componentClass={ControlLabel}><Translate value='razSourceOne' />{':'}</Col>
                <Col sm={10}><FormControl className='input-sm' componentClass="select" placeholder="select" defaultValue={krazSrc} disabled={props.svod.isLoading}
                    onChange={(e) => setParam("krazSrc", parseInt(e.target.value, 10))} bsSize='small' style={{ width: "auto" }}>
                    <FilterRazOptions />
                </FormControl></Col>
            </FormGroup>
            {props.userInfo.user.accessLevel < 4 ? <SelectMultiObjects isSelOrg={true} setTobj={(tobj) => setParam("tobj",  tobj )}
                setObl={(kobl) => setParam("kobl",  kobl )} setObls={(kobls) => setParam("kobls",  kobls )}
                setRaion={(kraion) => setParam("kraion", { kraion })} setRaions={(kraions) => setParam("kraions",  kraions )}
                setOrg={(korg) => setParam("korg", korg)} setOrgs={(korgs) => setParam("korgs", korgs)} year={year}
            /> : ""}
            {showSubOrgs()}
            {props.userInfo.user.accessLevel === 4 ? <FormGroup bsSize='small'>
                <Col sm={2} className="text-right" bsSize='small'><ControlLabel><Translate value='org' />{':'}</ControlLabel></Col>
                <Col sm={10} bsSize='small'>{props.userInfo.user.kodOrg} {props.i18n.locale[0] === 'r' ? props.userInfo.user.nameOrg : props.userInfo.user.nameOrgKz}</Col>
            </FormGroup> : ""}
            <SelectMultiForms setForms={(kforms) => setParam("kforms",  kforms)} no47={false} isNotMonthWithInc={true} />
            {errors.length > 0 ? <Row bsSize='small'>
                <Col sm={10} bsSize='small' smOffset={2}> <ul>{errors.map(m => <li className="text-danger"><strong>{I18n.t(m)}</strong></li>)}</ul></Col>
            </Row> : ""}
            {svodRunning === true ? <Row>
                <Col smOffset={2} sm={10}>
                    <div className='text-center'><img src="../images/loading.gif" alt={I18n.t('waitsvod')} width={20} />{' '}<strong><Translate value="waitsvod" /></strong></div>
                </Col>
            </Row> : ''}
            {svodRunning === false && successMessage != null ? <Row bsSize='small'>
                <Col smOffset={2} sm={10}>
                    <Alert bsStyle="success"><Translate value="svodComplete" /></Alert>
                </Col>
            </Row> : ''}
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    <Button className='btn btn-primary text-center' type="submit" disabled={props.svod.isLoading === true}><Translate value="execSvod" /></Button>
                </Col>
            </FormGroup>
        </Form>
        </>
}
export default connect(
    state => state,
    dispatch => bindActionCreators({ ...actionCreatorsNsi, ...actionCreatorsSvod }, dispatch)
)(SvodFormQuarts);